import adminLayout from '@/layouts/Admin'
import currentRouteMixin from '@/mixins/current-route.js'
import httpAxios from '@/utils/http-axios.js'
import modalComponent from '@/components/Modal'
import previewFileAudioComponent from '@/components/PreviewFileAudio'
import previewFileImageComponent from '@/components/PreviewFileImage'
import previewFileCustomComponent from '@/components/PreviewFileCustom'

export default {
    name: 'NumbersView',
    data() {
        return {
            number: {},
            currentNumberId: null,
            fileAudio: null,
            fileCoverImage: null,
            fileAnimationJsonLeft: null,
            fileAnimationJsonRight: null,
            fileWriteNumberAudio: null
        }
    },
    mixins: [currentRouteMixin],
    components: {adminLayout, modalComponent, previewFileAudioComponent, previewFileImageComponent, previewFileCustomComponent},
    mounted() {
        this.currentNumberId = this.currentRoute().params.id

        this.getNumber()
    },
    methods: {
        getNumber() {
            const self = this
            const appends = 'append=originalFillColor'

            httpAxios({
                url: self.$backendUrl + '/api/v1/numbers/' + self.currentNumberId + '?' + appends,
                method: 'GET'
            }).then(function(response) {
                self.number = response.data.number
            })
        },
        deleteNumber() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/numbers/' + self.currentNumberId,
                method: 'DELETE'
            }).then(function() {
                self.$router.push({ name: 'admin.numbers' })
            })
        },
        updateNumber() {
            const self = this
            let formData = new FormData()

            formData.append('_method', 'PUT')
            if(self.number.name) formData.append('name', self.number.name)
            if(self.number.original_fill_color) formData.append('fill_color', self.number.original_fill_color)
            if(self.fileFilledImage) formData.append('filled_image', self.fileFilledImage)
            if(self.fileBorderImage) formData.append('border_image', self.fileBorderImage)
            if(self.fileBorderGuidelinesImage) formData.append('border_guidelines_image', self.fileBorderGuidelinesImage)
            if(self.fileAudio) formData.append('audio', self.fileAudio)
            if (self.fileAnimationJsonLeft) formData.append('left_animation_json', self.fileAnimationJsonLeft)
            if (self.fileAnimationJsonRight) formData.append('right_animation_json', self.fileAnimationJsonRight)
            if (self.fileWriteNumberAudio) formData.append('write_number_audio', self.fileWriteNumberAudio)
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/numbers/' + self.currentNumberId,
                method: 'POST',
                data: formData
            }).then(function(response) {
                self.number = response.data.number

                self.notifySuccess()
            })
        },
        handleFileFilledImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileFilledImage = null
            }

            this.fileFilledImage = files[0]
        },
        handleFileBorderImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileBorderImage = null
            }

            this.fileBorderImage = files[0]
        },
        handleBorderGuidelinesImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileBorderGuidelinesImage = null
            }

            this.fileBorderGuidelinesImage = files[0]
        },
        handleFileAudio(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        },
        handleFileAnimationJsonLeft(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAnimationJsonLeft = null
            }

            this.fileAnimationJsonLeft = files[0]
        },
        handleFileAnimationJsonRight(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAnimationJsonRight = null
            }

            this.fileAnimationJsonRight = files[0]
        },
        handleFileWriteNumberAudio(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileWriteNumberAudio = null
            }

            this.fileWriteNumberAudio = files[0]
        }
    }
}